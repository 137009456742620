import React from "react";
import "./PoliticasPrivacidad.css";
import { Header } from "../hearder/Header";
import { TerminosCondiciones } from "../terminos-condiciones/TerminosCondiciones";

export const PoliticasPrivacidad = () => {
  const politicas = [
    {
      titulo: "Información Recabada",
      contenido: "La INFORMACIÓN CONFIDENCIAL, se hace referencia a toda aquella información de carácter personal, la información que solicitamos es: Nombre completo, teléfono, puesto y correo electrónico del usuario, con la finalidad de agilizar el proceso de navegación e interacción con el uso de los servicios en el portal, únicamente se almacenará la información necesaria para acceder al portal. nombre completo, correo electrónico, teléfono y nombre de la empresa, factuxin.com bajo ninguna circunstancia almacena y/o almacenará en sus bases de datos, servidores o cualquier otro medio de almacenamiento, información adicional del usuario.",
    },
    {
      titulo: "Finalidad de la información",
      contenido: "La INFORMACIÓN CONFIDENCIAL es utilizada para promover al usuario un servicio personalizado y acorde a sus necesidades, por lo cual el usuario acepta que se utilice la INFORMACIÓN CONFIDENCIAL para: promocionar los servicios ofrecidos por nuestro portal, envió de correo promocionales e informativos de los servicios recibidos, establecer contacto vía telefónica, envío electrónico sobre la calidad del servicio, aclaraciones vía telefónica o por correo electrónico.",
    },
    {
      titulo: "Protección de la Información Confidencial",
      contenido: "A partir que el usuario proporcione la INFORMACIÓN CONFIDENCIAL, nos comprometemos a cumplir con los estándares de seguridad y confidencialidad necesarios para procurar la privacidad de la información recabada y protegerla contra daño, perdida, alteración, destrucción, así como por el uso, acceso o tratamiento no autorizado.",
    },
    {
      titulo: "Transparencia de Datos Personales",
      contenido: "Es el derecho de toda persona de solicitar gratuitamente la información generada, administrada o en posesión de las autoridades públicas, quienes tienen la obligación de entregarla sin que la persona necesite acreditar intereses alguno ni justificar su uso.",
    },
    {
      titulo: "Derechos ARCO",
      contenido: "Como titular de tus datos personales, tiene derecho a conocer que datos personales tenemos de usted, para que los utilizamos y las condiciones de uso que les damos (Acceso), es su derecho solicitar la rectificación o corrección de sus datos personales, cuando estos seas inexactos o incompletos o no se encuentren actualizados (Rectificación). que la eliminemos de nuestros archivos, registros, expedientes, sistemas, bases de datos cuando considere que la misma no esta siendo utilizada adecuadamente (Cancelación), así como oponerse al uso de sus datos personales para fines específicos (Oposición). Estos derechos se conocen como derechos ARCO y están reconocidos en el artículo 16 de la Constitución Política de los Estados Unidos Mexicanos.",
    },
    {
      titulo: "Aceptación de los términos",
      contenido: "El presente aviso de privacidad constituye un acuerdo válido entre el usuario y los servicios y productos de factuxin.com, significa que ha leído, entendido y acordado los términos antes expuestos, si usted considera que su derecho de protección de datos personales ha sido lesionado por alguna conducta de parte de la empresa, si presume que el tratamiento de sus datos personales existe alguna violación de las disposiciones previstas en la Ley Federal de Protección de Datos Personales en Posesión de los Particulares (LFPDPPP), podrá interponer la queja o denuncia correspondiente ante el Instituto de Transparencia, Acceso a la Información y Protección de datos personales. Para mayor información visite: www.inai.org.mx",
      contenido2: "El cumplimiento con la Ley de Protección de Datos se traducirá en mayor confianza, satisfacción y tranquilidad por parte de los usuarios, pues saben que sus datos personales serán tratados responsablemente."
    },
  ]
  return (
    <>
      <Header />
      <div className="contenedor-aviso-privacidad">
        <div style={{ background: "#F4F7FF" }}>
          <h2 className="titulo-aviso-privacidad">
            Aviso de <span style={{ color: "#0F5EF6" }}>Privacidad</span>
          </h2>
          <div className="contenedor-preambulo">
            <div className="contenedor-texto-preambulo">
              <h3 className="preambulo">Preámbulo</h3>
              <p className="texto-preambulo">
                En cumplimiento con lo establecido en la Ley Federan de
                Protección de Datos Personales en Posición de Particulares
                (LFPDPPP), publicada el 5 de julio de 2010 en el Diario Oficial
                de la Federación y que entro en vigor el 6 de julio de 2010,
                contribuyen con nuestro objetivo de proteger sus datos
                personales, factuxin.com con domicilio en calle Miguel Cabrera
                627, Col. Centro, Oaxaca de Juárez, Oaxaca, C.P. 68000, es el
                responsable del uso, protección y tratamiento de sus Datos
                Personales y hace de su conocimiento que los datos
                proporcionados por usted serán tratados de manera confidencial.
              </p>
            </div>
            <img
              className="img-politicas"
              src="./images/images-privacidad/politicas.svg"
              alt="Ilustracion Politicas de Privacidad"
            />
          </div>
        </div>
        <div style={{ background: "#FFF" }}>
          <div className="listado-politicas">
            {politicas.map((item,index) => (
              <div key={index} className="politica">
                <img className="icon-checkbox" src="./images/images-privacidad/checkedBlue.svg" alt="checkbox azul" />
                <h3 className="titulo-politica">{item.titulo}</h3>
                <p className="texto-politica">{item.contenido}</p>
                <p className="texto-politica m-0">{item.contenido2}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
      <TerminosCondiciones />
    </>
  );
};
