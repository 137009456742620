import React from "react";
import "./Nosotros.css";
import { Header } from "../hearder/Header";
import { FooterLow } from "../footer/FooterLow";

export const Nosotros = () => {
  return (
    <>
    <Header />
      <div className="contenedor-nosotros">
        <div className="contenedor-texto-nosotros">
          <h1 className="titulo-nosotros">
            Sobre nosotros,{" "}
            <span style={{ color: "#0F5EF6" }}>
              Un poco de nuestra historia
            </span>
          </h1>
          <p className="texto-nosotros">
            "Factuxin" Somos un equipo de desarrollo de software
            establecido en la ciudad de México y Oaxaca, el proyecto nace
            de la necesidad de atender problemas contables, fiscales y de facturación para el comercio electrónico y los 
            ERP's ya existentes en el mercado. Nos enfocamos inicialmente a desarrollo ligado a sistemas administrativos. Con
            el paso de tiempo hemos ampliado nuestro catálogo de posibilidades y actualmente ofrecemos software enfocado al
            eCommerce y todo lo relacionado a la venta en línea.
          </p>
        </div>
        <img
          className="img-teamwork"
          src="./images/images-nosotros/amico.svg"
          alt="Ilustracion trabajo en equipo"
        />
      </div>
      <FooterLow />
    </>
  );
};
