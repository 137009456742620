import React from "react";
import "./Precios.css";
import { Header } from '../hearder/Header';
import { Footer } from "../footer/Footer";

export const Precios = () => {
  function logoOn (valor) {
    var elementos = document.getElementsByClassName("logo");
    switch(valor){
      case 0:
        elementos[valor].setAttribute('src','./images/images-cf/wooLogoWhite.svg');
        break;
      case 1:
        elementos[valor].setAttribute("src","./images/images-cf/amazonLogoWhite.svg");
        break;
      case 2:
        elementos[valor].setAttribute("src","./images/images-cf/shopifyLogoWhite.svg");
        break;
      case 3:
        elementos[valor].setAttribute("src","./images/images-cf/mlLogoWhite.svg");
        break;
      case 4:
        elementos[valor].setAttribute("src","./images/images-cf/prestashopLogoWhite.svg");
        break;
      default:
        break;
    }
  }  
  function logoOff (valor){
    var elementos = document.getElementsByClassName("logo");
    switch(valor){
      case 0:
        elementos[valor].setAttribute("src","./images/images-cf/wooLogo.svg");
        break;
      case 1:
        elementos[valor].setAttribute("src","./images/images-cf/amazonLogo.svg");
        break;
      case 2:
        elementos[valor].setAttribute("src","./images/images-cf/shopifyLogo.svg");
        break;
      case 3:
        elementos[valor].setAttribute("src","./images/images-cf/mlLogo.svg");
        break;
      case 4:
        elementos[valor].setAttribute("src","./images/images-cf/prestashopLogo.svg");
        break;
      default:
        break;
    }
  }
  return (
    <>
      <Header />
      <div className="contenerdorPrecios">
        <div className="header">
          <h1 className="titulo">Invierte más tiempo en tu negocio y menos en procesos de facturación, <span style={{ color: '#0F5EF6' }}>conecta la facturación de tu eCommerce</span></h1>
          <h2 className="subtitulo">Recuerda que puedes ir sumando folios de nuevas compras a tu paquete de facturación actual, primero debes crear tu cuenta factuxin y aprovechar los folios de regalo que tenemos para ti, despues puedes acceder a nuestra tienda e ir agregando paquetes de facturación para tu negocio.</h2>
        </div>
        <div className="logotipos text-center">
          <div onMouseOver={() => logoOn(0)} onMouseLeave={() => logoOff(0)}><img src="./images/images-cf/wooLogo.svg" className="logo" alt="logoMarca" /></div>
          <div onMouseOver={() => logoOn(1)} onMouseLeave={() => logoOff(1)}><img src="./images/images-cf/amazonLogo.svg" className="logo" alt="logoMarca" /></div>
          <div onMouseOver={() => logoOn(2)} onMouseLeave={() => logoOff(2)}><img src="./images/images-cf/shopifyLogo.svg" className="logo" alt="logoMarca" /></div>
          <div onMouseOver={() => logoOn(3)} onMouseLeave={() => logoOff(3)}><img src="./images/images-cf/mlLogo.svg" className="logo" alt="logoMarca" /></div>
          <div onMouseOver={() => logoOn(4)} onMouseLeave={() => logoOff(4)}><img src="./images/images-cf/prestashopLogo.svg" className="logo" alt="logoMarca" /></div>
        </div>

        <div className="tarjetasPrecios">
          <div className="tarjetasPrecios-contenedor">
            <div className="listado-caracteristicas">
              <div className="al-end">
                <h2 className="text-paquete">Paquete</h2>
              </div>
              <div>
                <h3 className="text-Nfacturas">25<sup id="tarjeta" className="text-facturas">/Folios</sup></h3>
              </div>
              <div className="caracteristicas resaltado-precio">
                <img src="./images/images-precios/checkBlue.svg" alt="checkmark" />
                <h4 className="text-general">$125.00 MXN</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">Link personalizado</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">Productos ilimitados</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">Asistencia técnica</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">Conexión con Mercado Libre, Shopify, WooCommerce</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">IVA incluido</h4>
              </div>
              <div>
                <a href="https://app.factuxin.com/register"> <button type="button" className="boton-comprar"><img src="./images/images-precios/carBlue.svg" alt="" />Conseguir ahora</button></a>
              </div>
            </div>
          </div>
          <div className="tarjetasPrecios-contenedor">
            <div className="listado-caracteristicas">
              <div className="al-end">
                <h2 className="text-paquete">Paquete</h2>
              </div>
              <div>
                <h3 className="text-Nfacturas">50<sup id="tarjeta" className="text-facturas">/Folios</sup></h3>
              </div>
              <div className="caracteristicas resaltado-precio">
                <img src="./images/images-precios/checkBlue.svg" alt="checkmarkcheckmark" />
                <h4 className="text-general">$225.00 MXN</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmarkcheckmark" />
                <h4 className="text-general">Link personalizado</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmarkcheckmark" />
                <h4 className="text-general">Productos ilimitados</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmarkcheckmark" />
                <h4 className="text-general">Asistencia técnica</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmarkcheckmark" />
                <h4 className="text-general">Conexión con Mercado Libre, Shopify , WooCommerce</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmarkcheckmark" />
                <h4 className="text-general">IVA incluido</h4>
              </div>
              <div>
                <a href="https://app.factuxin.com/register"><button type="button" className="boton-comprar"><img src="./images/images-precios/carBlue.svg" alt="" />Conseguir ahora</button></a>
              </div>
            </div>
          </div>
          <div className="tarjetasPrecios-contenedor">
            <div className="listado-caracteristicas">
              <div className="al-end">
                <h2 className="text-paquete">Paquete</h2>
              </div>
              <div>
                <h3 className="text-Nfacturas">100<sup id="tarjeta" className="text-facturas">/Folios</sup></h3>
              </div>
              <div className="caracteristicas resaltado-precio">
                <img src="./images/images-precios/checkBlue.svg" alt="checkmarkcheckmarkcheckmark" />
                <h4 className="text-general">$400.00 MXN</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmarkcheckmarkcheckmark" />
                <h4 className="text-general">Link personalizado</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">Productos ilimitados</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">Asistencia técnica</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">Conexión con Mercado Libre, Shopify, WooCommerce</h4>
              </div>
              <div className="caracteristicas">
                <img src="./images/images-precios/checkGrey.svg" alt="checkmark" />
                <h4 className="text-general">IVA incluido</h4>
              </div>
              <div>
                <a href="https://app.factuxin.com/register"><button type="button" className="boton-comprar"><img src="./images/images-precios/carBlue.svg" alt="" />Conseguir ahora</button></a>
              </div>
            </div>
          </div>
          
          

        </div>

        <div className="link-crear-cuenta">
          <h4>¿Aún no pruebas los beneficios de una cuenta factuxin? <a href="https://app.factuxin.com/register">Empezar ahora</a></h4>
        </div>
      </div>
      <Footer />
    </>
  )
}