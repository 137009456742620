import React from "react";
import "./Footer.css";
export const FooterTop = () => {
  return (
    <>
      <div className="contenedor-newsletter">
        <h2 className="texto-pregunta">
          ¿Te gustaría recibir información sobre las novedades de Factuxin e
          información importante relacionada con el tópico “Facturación ecommerce o marketplace”?
        </h2>
        <h3 className="texto-suscribete">
          Subscribete a nuestro newsletter y mantente informado sobre las
          novedades y actualizaciónes de Factuxin
        </h3>
        <div className="contenedor-suscribir">
          <div className="email col-6">
            <img
              className="email-icon"
              src="./images/images-footer/correoIcon.svg"
              alt="IconMail"
            />
            <input
              className="input-email"
              type="email"
              placeholder="Correo electrónico"
            />
          </div>
          <button
            type="button"
            className="boton-suscribirme col-6"
            href="#"
          >
            Suscribirme
          </button>
        </div>
      </div>
    </>
  );
};
