import React from "react";
import { FooterLow } from "../footer/FooterLow";
import "./Preguntas.css";

export const Preguntas = () => {
  const preguntasYrespuestas = [
    {
      pregunta: "¿Cómo funcionan los planes de pago factuxin?",
      respuesta:
        "Al adquirir tu paquete, los folios se acumulan a tu bolsa, tienes 365 días para hacer uso de ellos.",
    },
    {
      pregunta: "¿Que eCommerce son compatibles con factuxin?",
      respuesta:
        "Actualmente tenemos MercadoLibre, Shopify y Woocommerce. Continuamente estamos trabajando para anexar más canales.",
    },
    {
      pregunta: "¿Mi suscripción y folios caducan después de cierto tiempo?",
      respuesta:
        "Es correcto, tienen un año de vigencia.",
    },
    {
      pregunta: "¿Cuáles son las formas de pago con que puedo contratar?",
      respuesta:
        "Puedes pagar con tarjeta de crédito, debito o incluso en un oxxo, usamos MercadoPago para el procesamiento.",
    },
    {
      pregunta: "¿Puedo almacenar historiales de mis movimientos?",
      respuesta:
        "Si, tu historial se almacena por 5 años, posterior a eso, te enviamos un respaldo de tu base de datos y seguimos almacenando 5 años anteriores.",
    },
    {
      pregunta: "¿Mis clientes pueden almacenar su facturación?",
      respuesta:
        "Asi es, por cada vez que un cliente facture, se almacenarán sus documentos.",
    },
    {
      pregunta: "¿Cómo facturan mis clientes sus compras?",
      respuesta:
        "Ellos entran al link personalizado que puedes crear en tu perfil, solo con su numero de transacción ellos mismos generar su factura.",
    },
  ];
  const activar = (i) => {
    const pregunta = document.getElementsByClassName("pregunta");
    const respuesta = document.getElementsByClassName("respuesta");
    pregunta[i].classList.toggle("activa");
    respuesta[i].classList.toggle("activa");
  };
  return (
    <>
      <div style={{background: "#FFF"}}>
        <h2 className="titulo-preguntas">Preguntas frecuentes</h2>
        <hr className="linea" />
        <div className="contenedor-preguntas">
          {preguntasYrespuestas.map((item, index) => (
            <div key={index} className="contenedor-pregunta">
              <div className="pregunta" onClick={() => activar(index)}>
                <svg
                  width="28"
                  height="29"
                  viewBox="0 0 28 29"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M24.5 14.5C24.5 8.70312 19.7969 4 14 4C8.20312 4 3.5 8.70312 3.5 14.5C3.5 20.2969 8.20312 25 14 25C19.7969 25 24.5 20.2969 24.5 14.5Z"
                    stroke="#01295F"
                    strokeWidth="2.5"
                    strokeMiterlimit="10"
                  />
                  <path
                    d="M18.375 14.5H9.625M14 10.125V18.875V10.125Z"
                    stroke="#01295F"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
                <h3 className="question-text">{item.pregunta}</h3>
              </div>
              <p className="respuesta">{item.respuesta}</p>
            </div>
          ))}
        </div>
      </div>
      <FooterLow />
    </>
  );
};
