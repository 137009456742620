import React from "react";
import { Header } from "../hearder/Header";
import { Preguntas } from "../preguntas-frecuentes/Preguntas";
import "./Contacto.css";

export const Contacto = () => {
  return (
    <>
      <Header />
      <div className="contenedor-contacto">
        <h1 className="titulo-contacto-preguntas">Contacto & <span style={{ color: "#0F5EF6" }}>Preguntas frecuentes</span></h1>
        <div className="contenedor-infoYform-contacto">
          <div className="contenedor-informacion-contacto">
            <h2 className="subtitulo-contacto">Contacta con nosotros</h2>
            <p className="texto-informacion-contacto">
              En Factuxin somos expertos en impuestos digitales. Si
              existe alguna duda o queja acerca de nuestros productos, por favor
              comuniquese con nuestras líneas telefónicas o correos.
            </p>
            <h3 className="sub-subtitulo-contacto">Ubicación</h3>
            <div className="two-colrow">
              <img className="icon-info" src="./images/images-contacto/IconoHome.svg" alt="IconoHome" />
              <a className="texto-informacion-contacto" href="https://goo.gl/maps/KzexrJL7PXdimvBG8" rel={"noreferrer"} target={"_blank"}>Atenor Sala 113, Col. Antenor Sala,<br />Del. Benito Juarez, CDMX. CP. 03100</a>
              <a className="texto-informacion-contacto" href="https://goo.gl/maps/voyWGd1h2XbMPSLT9" rel={"noreferrer"} target={"_blank"}>Miguel Cabrera, #627, Int 1, P-1,<br />Centro, Oaxaca, CP. 68000</a>
            </div>
            <h3 className="sub-subtitulo-contacto">Teléfonos</h3>
            <div className="two-col-1">
              <img className="icon-info" src="./images/images-contacto/IconoTelefono.svg" alt="IconoTelefono" />
              <a className="texto-informacion-contacto" href="tel:+529511671455">(951) 167 14 55</a>
            </div>
            <h3 className="sub-subtitulo-contacto">Soporte</h3>
            <div className="two-colrow">
              <img className="icon-info" src="images/images-contacto/IconoCorreo.svg" alt="IconoCorreo" />
              <a className="texto-informacion-contacto" href="mailto:soporte@atti.com.mx">Soporte:<br />soporte@atti.com.mx</a>
              <a className="texto-informacion-contacto" href="mailto:ventas@atti.com.mx">Ventas:<br />ventas@atti.com.mx</a>
            </div>
            <h3 className="sub-subtitulo-contacto">Siguenos</h3>
            <div className="five-col">
              <a href="https://www.instagram.com/attiimx/" rel="noreferrer" target={"_blank"} ><img className="icon-red-social" src="images/images-contacto/IconoInstagram.svg" alt="IconoInstagram" /></a>
              <a href="https://twitter.com/attimx" rel="noreferrer" target={"_blank"} ><img className="icon-red-social" src="images/images-contacto/IconoTwitter.svg" alt="IconoTwitter" /></a>
              <a href="https://api.whatsapp.com/send?phone=+5215564560704&text=Deseo más información sobre el sistema de facturación." rel="noreferrer" target={"_blank"} ><img className="icon-red-social" src="images/images-contacto/IconoWhattsApp.svg" alt="IconoWhattsApp" /></a>
              <a href="https://www.facebook.com/attimx" rel="noreferrer" target={"_blank"} ><img className="icon-red-social" src="images/images-contacto/IconoFacebook.svg" alt="IconoFacebook" /></a>
            </div>
          </div>
          <div>
            <form action="" className="formulario-contacto">
              <div className="seven-row gap-30">
                <h2 className="subtitulo-contacto">Escribir mensaje</h2>
                <p className="texto-informacion-contacto">Escribanos sus dudas o quejas sobre Factuxin</p>
                <div className="two-col gap-30">
                  <div className="input-con-icon">
                    <img className="icon-input" src="./images/images-contacto/IconUser.png" alt="UserIcon" />
                    <input className="input-form" placeholder="Nombre" type="text" name="" id="" />
                  </div>
                  <div className="input-con-icon">
                    <img className="icon-input" src="./images/images-contacto/IconTel.png" alt="TelIcon" />
                    <input className="input-form" placeholder="Número de teléfono" type="tel" name="" id="" />
                  </div>
                </div>
                <div className="input-con-icon">
                  <img className="icon-input" src="./images/images-contacto/IconMail.png" alt="MailIcon" />
                  <input className="input-form" placeholder="Correo de contacto" type="email" name="" id="" />
                </div>
                <div className="input-con-icon">
                  <img className="icon-input" src="./images/images-contacto/IconPen.png" alt="PenIcon" />
                  <input className="input-form" placeholder="Asunto de mensaje" type="text" name="" id="" />
                </div>
                <textarea className="input-form text-area" placeholder="Mensaje" name="" id=""></textarea>
                <button className="boton-formulario-contacto" type="button">Enviar mensaje</button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Preguntas />
    </>
  );
};
