import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Header } from "../hearder/Header";
import { Footer } from "../footer/Footer";
import "./Home.css";
export const Home = () => {
  const [cambio, setcambio] = useState("/");
  useEffect(() => {
    const changeBG = () => {
      if (window.scrollY >= 100) {
        setcambio(0);
      } else {
        setcambio("/");
      }
    };
      window.addEventListener("scroll", changeBG);
    return () => {
      window.removeEventListener("scroll", changeBG);
    }
  }, [])
  
  function logoOn(valor) {
    var elementos = document.getElementsByClassName("logo");
    switch (valor) {
      case 0:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/wooLogoWhite.svg"
        );
        break;
      case 1:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/amazonLogoWhite.svg"
        );
        break;
      case 2:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/shopifyLogoWhite.svg"
        );
        break;
      case 3:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/mlLogoWhite.svg"
        );
        break;
      case 4:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/prestashopLogoWhite.svg"
        );
        break;
      default:
        break;
    }
  }
  function logoOff(valor) {
    var elementos = document.getElementsByClassName("logo");
    switch (valor) {
      case 0:
        elementos[valor].setAttribute("src", "./images/images-cf/wooLogo.svg");
        break;
      case 1:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/amazonLogo.svg"
        );
        break;
      case 2:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/shopifyLogo.svg"
        );
        break;
      case 3:
        elementos[valor].setAttribute("src", "./images/images-cf/mlLogo.svg");
        break;
      case 4:
        elementos[valor].setAttribute(
          "src",
          "./images/images-cf/prestashopLogo.svg"
        );
        break;
      default:
        break;
    }
  }
  return (
    <>
      <Header cambio={cambio} />
      <div className="contenedor-home">
        <div className="contenedor-presentacion">
          <div className="contenido-presentacion">
            <div className="texto-presentacion">
              <h1 className="titulo-presentacion">
                Tus procesos de{" "}
                <span style={{ color: "#0F5EF6" }}>facturación</span> de manera
                rápida, segura y factible
              </h1>
              <h2 className="subtitulo-presentacion">
                Factuxin es la herramienta perfecta para la facturación de tu
                eCommerce
              </h2>
              <p className="parrafo-presentacion">
                Factuxin es una herramienta especializada en facturación de
                comercios digitales,mercado libre, amazon, WooCommerce, shopify
                y prestashop, automatiza tus procesos de facturación, crea
                cotizaciónes o envía a tus clientes un enlace compartido para
                facturar su compra.
              </p>
              <a href = "https://app.factuxin.com/register" className="boton-presentacion text-center">
              <button className="boton-presentacion" type="button">
                Iniciar periodo de prueba
              </button>
              </a>
            </div>
            <div className="imagen-presentacion">
              <img
                className="img-ecommerce"
                src="./images/images-home/ecommerce.svg"
                alt="ecommerceIlustratio"
              />
            </div>
          </div>
          <img
            className="olas"
            src="./images/images-home/Wave.svg"
            alt="Waves"
          />
        </div>
        <div>
          <div className="hola">
            <div className="contenedor-encabezado">
              <h2 className="txt-titulo">
                <span style={{ color: "#0F5EF6" }}>
                  ¡Conecta la facturación de tu eCommerce,
                </span>{" "}
                crea cotizaciones, automatiza e invierte más tiempo en otras
                actividades de tu negocio!
              </h2>
              <h3 className="txt-subtitulo">
                Recuerda que al crear una cuenta factuxin{" "}
                <span style={{ color: "#0F5EF6" }}>
                  te regalamos 10 folios gratis
                </span>{" "}
                para que los utilices en tus ventas, despues podrás acceder a la
                página de tienda y agregar paquetes de folios de acuerdo a tus
                necesidades.
              </h3>
            </div>
            <div className="logotipos text-center">
              <div
                onMouseOver={() => logoOn(0)}
                onMouseLeave={() => logoOff(0)}
              >
                <img
                  src="./images/images-cf/wooLogo.svg"
                  className="logo"
                  alt="logoMarca"
                />
              </div>
              <div
                onMouseOver={() => logoOn(1)}
                onMouseLeave={() => logoOff(1)}
              >
                <img
                  src="./images/images-cf/amazonLogo.svg"
                  className="logo"
                  alt="logoMarca"
                />
              </div>
              <div
                onMouseOver={() => logoOn(2)}
                onMouseLeave={() => logoOff(2)}
              >
                <img
                  src="./images/images-cf/shopifyLogo.svg"
                  className="logo"
                  alt="logoMarca"
                />
              </div>
              <div
                onMouseOver={() => logoOn(3)}
                onMouseLeave={() => logoOff(3)}
              >
                <img
                  src="./images/images-cf/mlLogo.svg"
                  className="logo"
                  alt="logoMarca"
                />
              </div>
              <div
                onMouseOver={() => logoOn(4)}
                onMouseLeave={() => logoOff(4)}
              >
                <img
                  src="./images/images-cf/prestashopLogo.svg"
                  className="logo"
                  alt="logoMarca"
                />
              </div>
            </div>
            <div>
              <img
                className="imagenes-muestra"
                src="./images/images-home/imagen.svg"
                alt="ImagenesMuestra"
              />
            </div>
            <img className="curva" src="./images/images-home/curva.svg" alt="curva-contenedor" />
          </div>
          <div className="contenedor-tarjetasyparaquien">
            <div className="paraquienes">
              <h3 className="tema-listado">Negocios digitales</h3>
              <h4 className="titulo-listado">
                ¿Para quien es{" "}
                <span style={{ color: "#0F5EF6" }}>factuxin?</span>
              </h4>
              <p className="texto-listado">
                Si tienes un comercio digital, una tienda de venta de ropa,
                calzado, insumos para oficina, materiales de construcción,
                productos de limpieza o cualquier otra categoría de venta y
                deseas evitar los engorrosos procesos de facturación, entonces
                factuxin es para ti. Este software está desarrollado para
                facilitar y automatizar los procesos de facturación de tu
                comercio digital, al crear una cuenta podrás:
              </p>
              <div className="listado">
                <img
                  src="./images/images-home/checkedIcon.svg"
                  alt="checkmark"
                />
                <p className="texto-listado">
                  Personalizar los datos de tu facturación, como logotipos y
                  datos de contacto de tu negocio.
                </p>
              </div>
              <div className="listado">
                <img
                  src="./images/images-home/checkedIcon.svg"
                  alt="checkmark"
                />
                <p className="texto-listado">
                  Crear cotizaciones, enviarlas a tus clientes, alamcenarlas o
                  convertirlas en facturas al conciliar una venta.
                </p>
              </div>
              <div className="listado">
                <img
                  src="./images/images-home/checkedIcon.svg"
                  alt="checkmark"
                />
                <p className="texto-listado">
                  Generar mensajes automáticos para que tus clientes puedan
                  generar sus facturas al finalizar su compra.
                </p>
              </div>
              <div className="listado">
                <img
                  src="./images/images-home/checkedIcon.svg"
                  alt="checkmark"
                />
                <p className="texto-listado">
                  Graficar todos los movimientos de facturación durante periodos
                  de tiempo, entre muchas otras opciones.
                </p>
              </div>
              <a href="/comofunciona">
              <button
                className="boton-presentacion boton-paraquien"
                type="button"
                href="#"
              >
                Aprender más
              </button>
              </a>
            </div>
            <div className="contenedor-tarjetas">
              <div className="tarjeta">
                <img
                  src="./images/images-home/IconTarjeta1.svg"
                  alt="IconoTarjeta"
                />
                <h3 className="titulo-tarjeta">Personalización</h3>
                <p className="texto-tarjeta">
                  Agrega el logotipo de tu comercio o empresa y configura los
                  datos de contacto que aparecerán en las facturas, cotizaciones
                  o correos que envíes a tus clientes.
                </p>
              </div>
              <div className="tarjeta-r">
                <img
                  src="./images/images-home/IconTarjeta2.svg"
                  alt="IconoTarjeta"
                />
                <h3 className="titulo-tarjeta" style={{ color: "#FFF" }}>
                  Conexiones
                </h3>
                <p className="texto-tarjeta" style={{ color: "#FFF" }}>
                  Factuxin es una herramienta que se especializa en la
                  facturación de comercios digitales y cuenta con compatibilidad
                  para las principales eCommerce: Amazon, Shopify, Mercado
                  libre, WooCommerce y prestashop.
                </p>
              </div>
              <div className="tarjeta">
                <img
                  src="./images/images-home/IconTarjeta3.svg"
                  alt="IconoTarjeta"
                />
                <h3 className="titulo-tarjeta">Facilidad de uso</h3>
                <p className="texto-tarjeta">
                  Hemos elaborado una herramienta de facil acceso con una curva
                  de aprendizaje ligera, navegar entre los paneles de
                  administración te resultará facil al igual que realizar la
                  configuración de todos tus datos.
                </p>
              </div>
              <div className="tarjeta">
                <img
                  src="./images/images-home/IconTarjeta4.svg"
                  alt="IconoTarjeta"
                />
                <h3 className="titulo-tarjeta">Envíos y descargas</h3>
                <p className="texto-tarjeta">
                  Puedes acceder a historiales de facturación, creación de
                  nuevas cotizaciones y enviarlas a tus clientes. Envía o
                  descarga en formatos PDF, XLS o XML, tus movimientos
                  realizados.
                </p>
              </div>
            </div>
          </div>
          <div className="home-parte3">
            <h2 className="titulo-parte3">
              ¡Factura Online y{" "}
              <span style={{ color: "#0F5EF6" }}>
                controla toda tu actividad fiscal
              </span>{" "}
              desde la nube!
            </h2>
            <h3 className="subtitulo-parte3">
              Factuxin es una herramienta que se aloja en la nube, cada que
              desees realizar algún movimiento de facturación puedes ingresar
              desde cualquier dispositivo y en cualquier ubicación, solo
              necesitas tener tus credenciales de acceso y puedes empezar a
              facturar, crear cotizaciones, revisar historiales entre muchas
              opciones más.
            </h3>
          </div>
          <img
            className="img-dashboard"
            src="images/images-home/Dashboard.svg"
            alt="dashboard"
          />
          <div className="home-parte4">
            <div className="contenedor-cartas">
              <img
                className="cart"
                src="./images/images-home/cart1.svg"
                alt="GraficaEjemplo"
              />
              <img
                className="cart"
                src="./images/images-home/cart2.svg"
                alt="GraficaEjemplo"
              />
            </div>
            <div className="contenedor-texparte4-home">
              <h3 className="tema-listado">Negocios digitales</h3>
              <h4 className="titulo-listado titulo-texto-part4">
                <span style={{ color: "#0F5EF6" }}>
                  Grafíca tus movimientos de facturación
                </span>{" "}
                y compara a lo largo del tiempo{" "}
              </h4>
              <p className="subtitulo-parte3">
                Mantente actualizado y revisa los módulos de estadísticas que
                factuxin va generando a lo largo del tiempo, visualiza los
                montos facturados en la semana, mes o año, impuestos generados,
                entre otros; mantente al día con los movimientos de tu negocio.
              </p>
            </div>
          </div>
          <div className="img-background2">
            <div className="home-parte5">
              <img
                className="img-invoice"
                src="./images/images-home/invoice.svg"
                alt="InvoiceIlustration"
              />
              <div className="contenedor-cotizaciones">
                <h3 className="titulo-cotizaciones">Cotizaciones</h3>
                <h4 className="subtitulo-cotizaciones">
                  Crea cotizaciónes desde tu panel Factuxin, envía a tus
                  clientes y al cerrar la venta conviertelas en facturas.
                </h4>
                <p className="texto-cotizaciones">
                  Factuxin te permite agregar tus catálogos de productos al
                  panel de administrador. Agrega productos de tu stock a nuevas
                  listas y genera cotizaciones rapidas, envía por mail o
                  descarga y comparte por WhatsApp, después al concretar la
                  venta convierte esta en factura y almacena los procesos como
                  cierre de venta.
                </p>
                <a href="https://app.factuxin.com/register">
                <button className="boton-prueba" type="button">
                  Iniciar periodo de prueba
                </button>
                </a>
              </div>
            </div>
          </div>
          <div className="home-parte6">
            <div className="contenedor-texto-homeParte6">
              <h2 className="titulo-parte6">
                <span style={{ color: "#0F5EF6" }}>
                  ¡Factuxin es un sistema flexible
                </span>{" "}
                que te permite controlar parametros de facturación de forma
                sencilla!
              </h2>
              <h3 className="subtitulo-parte6">
                Invierte más tiempo en tu negocio y menos en procesos de
                facturación, otorga a tus clientes la posibilidad de generar su
                factura de manera rápida desde un enlace que factuxin genera y
                evita los tardados procesos de captura de datos, localización de
                clientes y envíos de archivos, algunos de los beneficos que
                factuxin te ofrece son:
              </h3>
            </div>
            <div className="contenedor-tarjetas-beneficios">
              <div className="tarjeta-beneficio">
                <img src="./images/images-home/Icono1.svg" alt="IconoTarjeta" />
                <h3 className="titulo-tarjeta-beneficio">
                  Almacenamiento automático
                </h3>
                <p className="texto-tarjeta-beneficio">
                  Al generarse una factura, ésta será procesada para que tu
                  cliente pueda guardarla y se almacenará de igual manera en tu
                  panel de administración.
                </p>
              </div>
              <div className="tarjeta-beneficio">
                <img src="./images/images-home/Icono2.svg" alt="IconoTarjeta" />
                <h3 className="titulo-tarjeta-beneficio">
                  Configura intervalos de tiempo para facturación
                </h3>
                <p className="texto-tarjeta-beneficio">
                  Controla los tiempos de facturación de tus clientes. Desde el
                  panel de configuración delimita el tiempo disponible en que los clientes pueden para
                  llevarla a cabo.
                </p>
              </div>
              <div
                className="tarjeta-beneficio"
                style={{
                  background:
                    "linear-gradient(180deg, #0F5EF6 0%, #232ED1 100%)",
                }}
              >
                <img src="./images/images-home/Icono3.svg" alt="IconoTarjeta" />
                <h3
                  className="titulo-tarjeta-beneficio"
                  style={{ color: "#FFF" }}
                >
                  Procesos rápidos de facturación por parte del cliente
                </h3>
                <p
                  className="texto-tarjeta-beneficio"
                  style={{ color: "#FFF" }}
                >
                  Configura el link de facturación para tus clientes y
                  personaliza el mensaje de correo a enviar para que estos
                  mismos puedan realizar su factura al finalizar su compra.
                </p>
              </div>
              <div className="tarjeta-beneficio">
                <img src="./images/images-home/Icono4.svg" alt="IconoTarjeta" />
                <h3 className="titulo-tarjeta-beneficio">
                  Factura tu eCommerce a precios asequibles
                </h3>
                <p className="texto-tarjeta-beneficio">
                  Paga por los folios que utilizas, accede a nuestra tienda y
                  escoge el paquete de facturas que mas te convenga, factuxin se
                  aleja de rentas mensuales y cuotas forzosas.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
