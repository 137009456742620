import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { Home } from "./componentes/home/Home";
import { ComoFunciona } from "./componentes/como-funciona/ComoFunciona";
import { Precios } from "./componentes/planes-precios/Precios";
import { Contacto } from "./componentes/contacto/Contacto";
import { Nosotros } from "./componentes/nosotros/Nosotros";
import { PoliticasPrivacidad } from "./componentes/politicas-privacidad/PoliticasPrivacidad";
import { Registro } from "./componentes/registro/Registro";
import ScrollToTop from "./componentes/ScrollToTop";
import "./App.css";

function App() {
  return (
    <>
      <Router>
        <ScrollToTop />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route path="/comofunciona" component={ComoFunciona} />
          <Route path="/precios" component={Precios} />
          <Route path="/contacto-preguntasfrecuentes" component={Contacto} />
          <Route path="/nosotros" component={Nosotros} />
          <Route path="/privacidad-terminos" component={PoliticasPrivacidad} />
          <Route path="/registro" component={Registro} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
