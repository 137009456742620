import React from 'react';
import './Comofunciona.css';
import { Footer } from "../footer/Footer";
import { Header } from '../hearder/Header';

export const ComoFunciona = () => {
  function logoOn(valor) {
    var elementos = document.getElementsByClassName("logo");
    switch (valor) {
      case 0:
        elementos[valor].setAttribute('src', './images/images-cf/wooLogoWhite.svg');
        break;
      case 1:
        elementos[valor].setAttribute("src", "./images/images-cf/amazonLogoWhite.svg");
        break;
      case 2:
        elementos[valor].setAttribute("src", "./images/images-cf/shopifyLogoWhite.svg");
        break;
      case 3:
        elementos[valor].setAttribute("src", "./images/images-cf/mlLogoWhite.svg");
        break;
      case 4:
        elementos[valor].setAttribute("src", "./images/images-cf/prestashopLogoWhite.svg");
        break;
      default:
        break;
    }
  }
  function logoOff(valor) {
    var elementos = document.getElementsByClassName("logo");
    switch (valor) {
      case 0:
        elementos[valor].setAttribute("src", "./images/images-cf/wooLogo.svg");
        break;
      case 1:
        elementos[valor].setAttribute("src", "./images/images-cf/amazonLogo.svg");
        break;
      case 2:
        elementos[valor].setAttribute("src", "./images/images-cf/shopifyLogo.svg");
        break;
      case 3:
        elementos[valor].setAttribute("src", "./images/images-cf/mlLogo.svg");
        break;
      case 4:
        elementos[valor].setAttribute("src", "./images/images-cf/prestashopLogo.svg");
        break;
      default:
        break;
    }
  }
  return (
    <>
      <div className="contenedor-comofunciona">
        <Header />
        <div className="contenedor-introduccion">
          <div className="contenedor-texto-introduccion">
            <h1 className="titulo-introduccion">Permite <span style={{ color: "#0F5EF6" }}>que tus clientes generen sus facturas</span> desde un enlace compartido</h1>
            <h2 className="subtitulo-introduccion">Proceso inmediato, facil de utilizar y sin complicaciónes</h2>
            <p className="texto-introduccion">Elimina los tediosos procesos de creación y envíos de facturación y permite que tus clientes  generen su facturación desde un enlace compartido al finalizar su compra en tu eCommerce.</p>
          </div>
          <img className="image-email" src="./images/images-cf/mail-sent.svg" alt="mail-sent" />
        </div>
        <img className="image-wave" src="./images/images-cf/Wave.svg" alt="waves" />
        <div className="contenedor-conexion text-center">
          <div className="contenedor-texto-conexion">
            <h2 className="titulo-conexion">¡Factuxin <span style={{ color: "#0F5EF6" }}>controla tu actividad fiscal</span> desde un solo lugar!</h2>
            <h3 className="subtitulo-conexion">Factuxin es la herramienta perfecta para la facturación de tu comercio digital, conecta WooCommerce, amazon, shopify, Mercado libre o prestashop. Genera cotizaciones, personaliza tus datos y permite a tus clientes facturar al finalizar su compra.</h3>
          </div>
          <div className="logotipos">
            <div onMouseOver={() => logoOn(0)} onMouseLeave={() => logoOff(0)}><img src="./images/images-cf/wooLogo.svg" className="logo" alt="logoMarca" /></div>
            <div onMouseOver={() => logoOn(1)} onMouseLeave={() => logoOff(1)}><img src="./images/images-cf/amazonLogo.svg" className="logo" alt="logoMarca" /></div>
            <div onMouseOver={() => logoOn(2)} onMouseLeave={() => logoOff(2)}><img src="./images/images-cf/shopifyLogo.svg" className="logo" alt="logoMarca" /></div>
            <div onMouseOver={() => logoOn(3)} onMouseLeave={() => logoOff(3)}><img src="./images/images-cf/mlLogo.svg" className="logo" alt="logoMarca" /></div>
            <div onMouseOver={() => logoOn(4)} onMouseLeave={() => logoOff(4)}><img src="./images/images-cf/prestashopLogo.svg" className="logo" alt="logoMarca" /></div>
          </div>
          <img className="image-style" src="./images/images-home/Dashboard.svg" alt="dashboard" />
        </div>
        <div className="contenedor-aprendizaje-parte1">
          <div className="contenedor-texto-parte1">
            <h2 className="texto-aprendizaje">Aprendizaje</h2>
            <h3 className="titulo-aprendizaje">¿Cómo empezar a utilizar Factuxin?</h3>
            <h4 className="texto-contenido">Utilizar este software es relativamente fácil, a continuación te mostramos los pasos simplificados para tu orientación.</h4>
            <div className="instruccion">
              <img src="images/images-cf/checked.svg" alt="checkmark" />
              <p className="texto-contenido">Primero debes crear una cuenta factuxin para poder ingresar a tu panel de administración</p>
            </div>
            <a href="https://app.factuxin.com/register">
            <button className="boton-aprendizaje" type="button">Crear una cuenta factuxin</button>
            </a>
          </div>
          <img className="images-aprendizaje" src="images/images-cf/formularioR.svg" alt="RegistroFormulario" />
        </div>
        <div className="contenedor-aprendizaje-parte2">
          <img className="images-aprendizaje imagen" src="./images/images-cf/config.png" alt="PaginaConfiguracion" />
          <div className="contenedor-texto-parte2">
            <h2 className="texto-aprendizaje">Aprendizaje</h2>
            <h3 className="titulo-aprendizaje">¿Configuración de usuario?</h3>
            <h4 className="texto-contenido">Para comenzar a utilizar tu facturación online, debes configurar tus datos de usuario, desde la pestaña general, aquí deberás:</h4>
            <div className="instruccion">
              <img src="images/images-cf/checked.svg" alt="checkmark" />
              <p className="texto-contenido">Ingresar el logotipo de tu comercio, el cual saldrá impreso en las facturas que generes. El tamaño recomendado debe ser aproximado 500px x 500px.</p>
            </div>
            <div className="instruccion">
              <img src="images/images-cf/checked.svg" alt="checkmark" />
              <p className="texto-contenido">Personalizar tu link de facturación, el cual compartirás con tus clientes, recuerda que con este acceso podrán ingresar y generar su factura por cuenta propia.</p>
            </div>
            <div className="instruccion">
              <img src="./images/images-cf/checked.svg" alt="checkmark" />
              <p className="texto-contenido">Completa tus datos fiscales para un uso correcto de la herramienta.</p>
            </div>
          </div>
        </div>
        <div className="contenedor-aprendizaje-parte3">
          <div className="contenedor-texto-parte3">
            <h2 className="texto-aprendizaje">Aprendizaje</h2>
            <h3 className="titulo-aprendizaje">Configurar mensajes y tiendas</h3>
            <h4 className="texto-contenido">Personaliza tu mensaje de contacto vendedor-cliente y activa tu tienda a facturar.</h4>
            <div className="instruccion">
              <img src="./images/images-cf/checked.svg" alt="checkmark" />
              <p className="texto-contenido">Puedes personalizar el mensaje que se enviará a tu cliente despues de finalizar su compra, este se enviará mediante un correo electrónico o por mensaje postventa de forma automática.</p>
            </div>
            <div className="instruccion">
              <img src="./images/images-cf/checked.svg" alt="checkmark" />
              <p className="texto-contenido">Configura el tiempo y el número de días activos para facturar (recuerda que despues de los días escogidos el sistema ya no permitirá llevar a cabo el proceso).</p>
            </div>
          </div>
          <img className="images-aprendizaje" src="images/images-home/Mensajefacturacion.svg" alt="PantallaFacturacion" />
        </div>
        <div className="contenedor-aprendizaje-parte4">
          <img className="images-aprendizaje imagen" src="images/images-home/Sellosdigitales.svg" alt="PantallaSellosDigitales" />
          <div className="contenedor-texto-parte4">
            <h2 className="texto-aprendizaje">Aprendizaje</h2>
            <h3 className="titulo-aprendizaje">Valida tus certificados de sellos digitales (CSD) y tu (e.firma)</h3>
            <h4 className="texto-contenido">Tu e.firma es única, es un archivo seguro y cifrado, que tiene la validez de una firma autógrafa. Por sus características, es segura y garantiza tu identidad, este solo es necesario si quieres que descarguemos tus facturas desde el portal del SAT.</h4>
            <div className="instruccion">
              <img src="images/images-cf/checked.svg" alt="checkmark" />
              <p className="texto-contenido">Valida tu identificación subiendo las credenciales de tu CSD.</p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
