import React from "react";
import "./Footer.css";
import {FooterTop} from "./FooterTop"
import {FooterLow} from "./FooterLow"
export const Footer = () => {
  return (
    <>
      <footer>
        <FooterTop />
        <FooterLow />
      </footer>
    </>
  );
};
