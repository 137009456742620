import React from "react";
import { useLocation, Link } from "react-router-dom";
import "./Header.css";
export const Header = (props) => {
  const logoPath = "./images/images-header/";
  const styles = [
    "navbar fixed-top navbar-expand-xl ",
    "navbar-toggler",
    "navbar-toggler-icon",
    "nav-link active ",
  ];
  let location = useLocation().pathname;
  if (props.cambio === 0) {
    location = 0;
  } else if (props.cambio === "/") {
    location = "/";
  }

  return (
    <div>
      <nav
        className={
          location === "/" ? styles[0] + "estilo" : styles[0] + "h-header"
        }
      >
        <div className="container-fluid">
        <a href="/">
          <img
            src={
              location === "/"
                ? logoPath + "LogoWhite.svg"
                : logoPath + "LogoBlue.svg"
            }
            className="factuxin_logo"
            alt="LogoFactuxin"
          />
          </a>
          <button
            className={
              location === "/" ? styles[1] + " toggler-home" : styles[1]
            }
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span
              className={
                location === "/" ? styles[2] + " icon-toggler-home" : styles[2]
              }
            ></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <ul className="navbar-nav ms-auto mb-lg-2">
              <div className="contenedor-listado">
                <li className="nav-item active">
                  <Link
                    className={
                      location === "/"
                        ? styles[3] + "texto-navbar-home"
                        : styles[3] + "texto-navbar"
                    }
                    aria-current="page"
                    to="/"
                  >
                    Home
                  </Link>
                </li>
                <li className="nav-item active dropdown">
                  <Link
                    className={
                      location === "/"
                        ? styles[3] + "texto-navbar-home dropdown-toggle"
                        : styles[3] + "texto-navbar dropdown-toggle"
                    }
                    aria-current="page"
                    to="/comofunciona"
                  >
                    ¿Como funciona?
                  </Link>
                </li>
                <li className="nav-item active">
                  <Link
                    className={
                      location === "/"
                        ? styles[3] + "texto-navbar-home"
                        : styles[3] + "texto-navbar"
                    }
                    aria-current="page"
                    to="/precios"
                  >
                    Precios
                  </Link>
                </li>
                <li className="nav-item active">
                <a href="https://app.factuxin.com/login">
                  <button type="button" className="boton-iniciar-sesion">
                    <img
                      className="Icon"
                      src="./images/images-header/SesionIcon.svg"
                      alt="UserIcon"
                    />
                    Iniciar sesión
                  </button>
                  </a>
                </li>
                <li className="nav-item active">
                  <a href="https://app.factuxin.com/register">
                    <button type="button" className="boton-crear-cuenta">
                      Crear cuenta
                    </button>
                  </a>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};
