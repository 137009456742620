import React from "react";
import "./Footer.css";
import { Link } from "react-router-dom";
export const FooterLow = () => {
  return (
    <>
      <div>

        <div className="contenedor-infoyform">
          <div className="contendor-info">
            <div className="fila1">
              <img
                className="logo-footer"
                src="./images/factuxin_logo_White.svg"
                alt="LogoFactuxin"
              />
            </div>
            <div>
              <h2 className="titulo-seccion">SABER MAS</h2>

              <Link className="texto-seccion" to="/nosotros">Sobre nosotros</Link>
              <Link className="texto-seccion" to="/privacidad-terminos">Terminos y condiciones</Link>
              <Link className="texto-seccion" to="/privacidad-terminos">Politicas de privacidad</Link>
              <Link className="texto-seccion" to="/contacto-preguntasfrecuentes">Contacto</Link>
            </div>
            <div>
              <h2 className="titulo-seccion">SOPORTE Y VENTAS</h2>
              <a href="tel:+529511671455" className="texto-seccion">
                Teléfono:
                <br />
                (951) 167 14 55
              </a>
              <a href="mailto:factuxin@gmail.com" className="texto-seccion">
                Mail:
                <br />
                factuxin@gmail.com
              </a>
            </div>
            <div>
              <h2 className="titulo-seccion">NAVEGACIÓN</h2>
              <Link className="texto-seccion" to="/">Home</Link>
              <Link className="texto-seccion" to="/comofunciona">¿Como funciona?</Link>
              
              <Link className="texto-seccion" to="/precios">Precios</Link>
              <a className="texto-seccion" href="https://app.factuxin.com/login">Iniciar sesión</a>
              <a className="texto-seccion" href="https://app.factuxin.com/register">Crear cuenta</a>

            </div>
            <div>
              <h2 className="titulo-seccion">COMUNIDAD</h2>
              <a href="https://atti.com.mx/wp/blog-2/" rel="noreferrer" target={"_blank"} className="texto-seccion">Blog</a>
              <Link className="texto-seccion" to="/contacto-preguntasfrecuentes">Preguntas frecuentes</Link>
            </div>
          </div>


          



        </div>
        <div className="background-redes">
          <div className="contenedor-redesyderechos">
            <h6 className="derechos mb-0">
              Powered by @Factuxin @2022 . All Rights Reserved
            </h6>
            <div className="redes">
              <h6 className="mb-0">Contacta con nosotros</h6>
              <a className="iconRedes" href="https://api.whatsapp.com/send?phone=+5215564560704&text=Deseo más información sobre el sistema de facturación." rel="noreferrer" target={"_blank"}>
                <img src="./images/images-footer/whatsappIcon.svg" alt="IconosRedes" />
              </a>
              <a className="iconRedes" href="https://www.facebook.com/attimx" rel="noreferrer" target={"_blank"}>
                <img src="./images/images-footer/facebookIcon.svg" alt="IconosRedes" />
              </a>
              <a className="iconRedes" href="https://youtube.com/@vetecno" target={"_blank"}>
                <img src="./images/images-footer/youtubeIcon.svg" alt="IconosRedes" />
              </a>
              <a className="iconRedes" href='https://twitter.com/attimx' rel="noreferrer" target={"_blank"}>
                <img src="./images/images-footer/twitterIcon.svg" alt="IconosRedes" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
