import React from "react";
import { FooterLow } from "../footer/FooterLow";
import "./TerminosCondiciones.css";

export const TerminosCondiciones = () => {
  const terminos = [
    {
      titulo: "Registro de cuentas de administrador y clientes",
      contenido:
        "Factuxin es un software de facturación que se ofrece a través  de https://factuxin.com/, los usuarios que pretendan acceder a este servicio deberán registrar una cuenta y brindar la información requerida, damos por entendido que todos los datos ingresados son reales y por lo tanto nos exonera  de poseer información falsa de cualquier persona. Bajo ninguna circunstancia Factuxin permite el ingreso de usuarios distintos de aquellos que el titular de la cuenta haya decidido dar de alta; recuerde que el número de usuarios que pueden ingresar va ligado al plan elegido por este mismo. Los usuarios calificados no podrán ser reemplazados por terceros y hacer uso de la platarforma, en caso de suceder esto, Factuxin no se hace responsable por el pernicioso de plataforma y los datos ingresados, sea ocasionado por el titular, usuarios, o personas terceras.",
    },
    {
      titulo: "Medios de pago",
      contenido:
        "Factuxin ofrece los medios de pago reseñados en su página web, para acceder a algunos de los sistemas de pago usted debe estar de acuerdo que es necesario registrar información personal como nombre del titular, número de cuenta, tarjeta de crédito o débito y datos requeridos para ejecutar el pago. Factuxin da por hecho que la información de la tarjeta de crédito ingresada para crear una cuenta es conocida por el titular de la cuenta por lo tanto no se hace responsable por el uso inadecuado de tarjetas de crédito al momento de esta ser usada para registrar una cuenta, entendiéndose como uso irresponsable, la falsificación y suplantación de la identidad del titular la cuenta.",
    },
    {
      titulo: "Seguridad de la cuenta",
      contenido:
        "Factuxin protege la seguridad de las contraseñas otorgadas por nuestros clientes titulares, usuarios habilitados, y clientes facturados, sin embargo, no puede velar por la confidencialidad ni responsabilidad sobre el cuidado de estas mismas, con esto damos por entendido que Factuxin no se responsabiliza por el mal uso de la contraseña por parte de los tipos de usuario ni por su fácil desciframiento, asumimos que siempre que se acceda al sistema, lo hará el usuario calificado.",
    },
    {
      titulo: "Aceptación de los términos",
      contenido:
        "Usted reconoce que ha leído nuestro aviso de privacidad disponible en el sitio https://factuxin.com/privacidad y nos expresa su total conformidad con el mismo. Si con motivo de cualquier requerimiento, notificación judicial, citatorio o cualquier otro acto determinado únicamente por las autoridades tributarias, nos vemos obligados a compartir información sobre como utiliza nuestros servicios, usted da por aceptado que debemos colaborar con el llamado de las autoridades.",
    },
    {
      titulo: "Comentarios, dudas o sugerencias",
      contenido:
        "En caso de existir consultas, quejas o reclamos sobre la información contenida en el presente documento de Términos y Condiciones, por favor escribir al correo soporte@factuxin.com, utilizando como asunto: Sobre políticas legales y términos de aceptación.",
    },
  ];
  return (
    <>
      <div className="contenedor-terminos">
        <div style={{ background: "#F4F7FF" }}>
          <h2 className="titulo-aviso-privacidad">
            Términos y Condiciones{" "}
            <span style={{ color: "#0F5EF6" }}>Del Servicio</span>
          </h2>
          <div className="contenedor-intro-terminos">
            <div className="contenedor-texto-politicas">
              <h3 className="preambulo">
                Políticas legales y de{" "}
                <span style={{ color: "#0F5EF6" }}>
                  aceptación del servicio
                </span>
              </h3>
              <p className="texto-preambulo">
                El presente documento se enmarca como un contrato entre usted y
                el servicio Factuxin, en el entendido de que comprende que
                utilizar cualquiera de nuestros servicios lo vinculan a la
                aceptación de todas las políticas legales que se enmarcan en
                este documento aplicables al momento de registrarse en el sitio
                web de https://factuxin.com/, de igual manera reconoce ser mayor
                de edad o si utiliza nuestros servicios para una persona moral,
                garantiza que tiene facultades para su representación.
              </p>
            </div>
            <img
              className="img-terminos"
              src="./images/images-terminos/wallet.svg"
              alt="Ilustracion app wallet"
            />
          </div>
        </div>
        <div style={{ background: "#FFF" }}>
          <div className="listado-politicas">
            {terminos.map((item, index) => (
              <div key={index} className="politica">
                <img
                  className="icon-checkbox"
                  src="./images/images-privacidad/checkedBlue.svg"
                  alt="checkbox azul"
                />
                <h3 className="titulo-politica">{item.titulo}</h3>
                <p className="texto-politica">{item.contenido}</p>
              </div>
            ))}
            <div className="politica">
              <img
                className="icon-checkbox"
                src="./images/images-privacidad/checkedBlue.svg"
                alt="checkbox azul"
              />
              <h3 className="titulo-politica">
                Información de la cuenta y datos ingresados
              </h3>
              <p className="texto-politica mb-0">
                Factuxin vela por mantener segura la información de los
                titulares de cuentas, de usuarios agregados y clientes
                registrados, mas no se responsabiliza por actos mal
                intencionados de terceros y las afectaciones que puedan generar
                a los clientes finales o usuarios del sistema.
                <br />
                Factuxin no se hace responsable por el tipo de información
                ingresada por los usuarios del sistema ni por los resultados
                inadecuados si los datos son ingresados de manera inadecuada de
                acuerdo a los parámetros contables y fiscales aplicables en
                México. Se da por entendido con esto, que los titulares y
                usuarios habilitados en el sistema harán un uso responsable de
                las herramientas que tienen a su disposición. Factuxin se
                exonera de cualquier uso indebido de su información por parte de
                cualquier usuario, entendiéndose por ello el usar información
                para evadir obligaciones tributarias, entre otras. <br />
                <br />
                Al utilizar los servicios de Factuxin, los usuarios se
                comprometen a:
              </p>
              <ul className="texto-politica mt-0">
                <li>
                  No utilizar el servicio con fines maliciosos o de mala
                  voluntad, para beneficiarse en perjuicio de terceros o del
                  Estado
                </li>
                <li>
                  No usar el servicio con fines criminales o ilegales para sacar
                  provecho o beneficio ilícito para sí mismo o para terceros
                </li>
                <li>
                  No causar daño físico, moral o mental a usuarios del servicio,
                  o terceras personas que sean beneficiarias por el uso del
                  software factuxin
                </li>
                <li>
                  No publicar información sensible personal y de datos fiscales
                  de los usuarios que pueda inducir a daños morales o físicos de
                  terceros
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <FooterLow />
    </>
  );
};
